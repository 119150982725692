<template>

	<!-- PREMIUM: AKTIV -->
	<div v-if="premiumStatus.validTo && !showPlanSelection" class="premium">
		<div class="premium__header">
			<img src="@/assets/notification/ic_notification_premium.svg">
			<h3>Premium-Mitgliedschaft</h3>
			<p>Danke für dein Vertrauen! Hier findest du alle Informationen zur Laufzeit sowie die Rabattcodes für unseren <a href="https://shop.sportlerplus.de/?utm_source=app&utm_medium=settings&utm_campaign=sportlerplus+referrals">Shop</a>.</p>
		</div>
		<div class="list">
			<div class="list__item">
				<div class="list__item__content">
					<div class="list__item__label">
						Premium-Mitgliedschaft aktiv bis
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ localeDateFromEpoch(premiumStatus.validTo.seconds) }}
				</div>
			</div>
			<div v-if="personalCode" class="list__item">
				<div class="list__item__content">
					<div class="list__item__label">
						Gutschein (20€)*
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ personalCode }}
				</div>
				<img @click="copyText(personalCode)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>
			<div class="list__item">
				<div class="list__item__content">
					<div class="list__item__label">
						Shop-Rabatt (20%)**
					</div>
				</div>
				<div class="list__item__value list__item__value--light">
					{{ rebateCode }}
				</div>
				<img @click="copyText(rebateCode)" src="@/assets/ic_16_copy.svg" style="margin-left: 8px;">
			</div>

			<v-btn v-if="enablePlanSelectionButton" class="button button--primary" @click="showPlanSelection = true">Jetzt verlängern</v-btn>
		</div>

		<div class="hint-text">
			<span v-if="personalCode">* Einlösbar ab einem Einkaufswert von 40€.<br></span>
			** Während der Vertragslaufzeit
		</div>	
    </div>

    <!-- PREMIUM: INAKTIV -->
	<div v-else class="premium">

		<h1 class="text-center">Jetzt alles freischalten.</h1>

		<ul class="premium__features">
			<li>Zugang zu allen Inhalten & Features</li>
			<li>Alle Workouts der Coaches</li>
			<li>Unbegrenzt persönliche Workouts</li>
		</ul>

		<div class="premium__selection">
			<div @click="setActive(12)" class="premium__selection__item is-active" ref="subscription_year">
				<div class="premium__selection__item__header">
					<div class="premium__selection__item__title">
						12 Monate <span>Spare 50%</span>
					</div>
					<div class="premium__selection__item__price">
						49,99 € <span>(4,17€ mtl.)</span>
					</div>
				</div>
				<ul>
					<li>30 Tage kostenlos testen</li>
					<li>20€ SportlerPlus Shop-Gutschein *</li>
					<li>Dauerhaft 10% Rabatt im Shop **</li>
				</ul>
			</div>
			<div @click="setActive(3)" class="premium__selection__item" ref="subscription_quarter">
				<div class="premium__selection__item__header">
					<div class="premium__selection__item__title">
						3 Monate
					</div>
					<div class="premium__selection__item__price">
						24,99 € <span>(8,33 mtl.)</span>
					</div>
				</div>
			</div>
		</div>

		<v-btn @click="purchase" class="button button--primary">Weiter</v-btn>

		<div class="hint-text">
			* Einlösbar ab einem Einkaufswert von 40€.<br>
			** Während der Vertragslaufzeit
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { copyTextToClipboard } from  '@/sportlerplus-helper';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
	name: 'Premium',
	data() { return {
		selectedPlan: 12,
		personalCode: null,
		rebateCode: "GS20-SPO+",
		showPlanSelection: false,
		enablePlanSelectionButton: false,
	}},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			if (this.premiumStatus.email && this.premiumStatus.code) {
				axios
				.get("https://apphelper.sportlerplus.com/getGiftCode?email=" + this.premiumStatus.email + "&product=" + this.premiumStatus.code)
				.then(response => {
					let giftCodeData = response.data;
					this.personalCode = giftCodeData.code;
				});

				if (!this.premiumStatus.code.startsWith("com.sportplatzmedia.sportlerplus.subscription")) {
					let endDate = new Date(0);
					endDate.setUTCSeconds(this.premiumStatus.validTo.seconds);
					let currentDate = new Date();
					if (this.dateDiffInDays(currentDate, endDate) < 5) {
						this.enablePlanSelectionButton = true;
					}
				}
			}
		},
		// a and b are javascript Date objects
		dateDiffInDays(a, b) {
			// Discard the time and time-zone information.
			const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
			const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

			return Math.floor((utc2 - utc1) / _MS_PER_DAY);
		},
		purchase() {
			if (this.selectedPlan == 12) {
				window.location = "inapppurchase://subscription12m";
			}
			else if (this.selectedPlan == 3) {
				window.location = "inapppurchase://subscription3m";
			}
		},
		setActive(plan) {
			if (plan == 3) {
				this.$refs['subscription_quarter'].classList.add("is-active");
				this.$refs['subscription_year'].classList.remove("is-active");
			}
			else if (plan == 12) {
				this.$refs['subscription_quarter'].classList.remove("is-active");
				this.$refs['subscription_year'].classList.add("is-active");
			}
			this.selectedPlan = plan;
		},
		localeDateFromEpoch(val) {
			let d = new Date(0);
			d.setUTCSeconds(val);
			return d.toLocaleDateString();
		},
        copyText(text) {
            copyTextToClipboard(text, "Code wurde in die Zwischenablage kopiert.");
        },
	},
	computed: {
		premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
	}
}
</script>

<style lang="scss">
	.premium {
		display: flex;
		flex-direction: column;
		flex: 1;

		&__header {
			text-align: center;
			margin-top: 6px;

			img {
				margin-bottom: 16px;
			}

			h3 {
				margin-bottom: 8px;
			}

			p {
				font-weight: 600;
				line-height: 20px;
				margin-bottom: 43px;
				opacity: 1;
				color: rgba($colorBlack, .48);
			}

			a {
				color: $colorPrimary;
			}
		}

		&__date {
			font-size: 17px;
			font-weight: 700;
			color: $colorBlack;
			text-align: center;
			background: rgba($colorBlack, .08);
			padding: 20px 8px 19px;
			border-radius: 8px;
			margin-bottom: auto;
		}

		&__features {
			padding: 0 !important;
			margin: 26px 0 36px;

			li {
				padding: 8px 0 8px 36px;
				position: relative;
				list-style-type: none;
				font-size: 14px;
				letter-spacing: -0.2px;
				font-weight: 600;
				line-height: 20px;
				color: $colorBlack;

				&:before {
					content: '';
					position: absolute;
					top: 4px;
					left: 0;
					width: 24px;
					height: 28px;
					background: url('../assets/ic_premium_check.svg')
				}
			}
		}

		&__selection {

			&__item {
				box-shadow: inset 0 0 0 2px rgba($colorBlack, .08);
				border-radius: 8px;
				padding: 0 16px;
				margin-bottom: 24px;
				box-sizing: border-box;
				transition: all .2s ease-in-out;

				&.is-active {
					background: rgba($colorPrimary, .08);
					box-shadow: inset 0 0 0 2px rgba($colorPrimary, 1), 0 8px 16px 0 rgba($colorPrimary, .24);
					transform: translateY(-4px);
				}

				&__header {
					display: flex;
					align-items: center;
					padding: 14px 0 15px;
				}

				&__title {
					font-size: 20px;
					font-weight: 700;
					letter-spacing: -0.2px;
					color: $colorBlack;
					display: flex;
					flex-direction: row;
					align-items: center;

					span {
						font-size: 11px;
						line-height: 26px;
						font-weight: 700;
						color: $colorPrimary;
						letter-spacing: -0.47px;
						background: rgba($colorPrimary, .16);
						padding: 0 9px;
						border-radius: 4px;
						margin-left: 8px;
					}
				}

				&__price {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					font-size: 14px;
					font-weight: 500;
					color: rgba($colorBlack, .48);
					margin-left: auto;

					span {
						font-size: 11px;
						font-weight: 600;
						color: rgba($colorBlack, .32);
						margin-top: -2px;

						.is-active & {
							color: rgba($colorPrimary, .48);
						}
					}

					.is-active & {
						color: $colorPrimary;
						font-weight: 600;
					}
				}

				ul {
					display: flex;
					flex-direction: column;
					padding: 0 0 4px 0 !important;
					margin-top: -4px;

					li {
						position: relative;
						list-style-type: none;
						padding: 11px 0 11px 24px;
						font-size: 14px;
						letter-spacing: -0.2px;
						font-weight: 600;
						line-height: 20px;
						color: $colorBlack;
						box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);

						&:last-child {
							box-shadow: none;
							margin-bottom: 4px;
						}

						&:before {
							content: '';
							position: absolute;
							top: 20px;
							left: 0;
							width: 10px;
							height: 2px;
							background: $colorPrimary;
						}

						&:after {
							content: '';
							position: absolute;
							top: 16px;
							left: 4px;
							width: 2px;
							height: 10px;
							background: $colorPrimary;
						}
					}
				}
			}
		}

		.hint-text {
			font-size: 11px;
			line-height: 16px;
			font-weight: 600;
			letter-spacing: -0.16px;
			color: rgba($colorBlack, .48);
			text-align: center;
			margin: -4px 0 12px;
		}

		.list {
			margin-bottom: auto;
		}
	}
</style>